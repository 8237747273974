// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

console.log('de')
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener("turbolinks:load", function() {
  const navToggle = document.getElementById("nav-toggle");
  const navList = document.getElementById("nav-list");

  if (navToggle) {
    navToggle.addEventListener("click", function() {
      navList.classList.toggle("open");
    });
  }
});

document.addEventListener('turbolinks:load', function () {
  const hamburgerMenu = document.querySelector('.hamburger-menu');
  const navList = document.querySelector('.nav-list');
  const dropdownToggle = document.querySelector('.dropdown-toggle');

  navList.classList.remove('active');

  hamburgerMenu.addEventListener('click', function () {
    console.log('click');
    navList.classList.toggle('active');
  });

  dropdownToggle.addEventListener('click', function (e) {
    e.preventDefault();
    dropdownToggle.classList.toggle('active');
  });
});